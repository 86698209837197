import * as React from "react";
import { useEffect, useState } from "react";
import IdleTimer from 'react-idle-timer';
import SessionManager from '../Authentication/SessionManager';

import { StoreState } from "../store";

// Import components
import LoaderSpinner from "../Commons/Components/LoaderSpinner";
import { useSelector } from "react-redux";

import FeatureToggleProvider from "./FeatureToggle/FeatureToggleProvider";
import UserConfig from "./UserConfig";
import I18NProvider from "./I18NProvider";
import ImpersonificationModeBar from "./Impersonification/impersonificationModeBar";
import Header from "./Header";
import Admin from "../Admin";
import Dashboard from "../Dashboard";
import Footer from '../Commons/Components/Footer';

import './index.css';

/* 
1. The feature toggle dictionary is loaded as this is needed to determine which features are enabled for the user. 
The feature toggle dictionary is a static dictionary that is loaded from the backend and stored in the Redux store.

2. The user configuration is loaded. This is needed to determine the user's language and other user-specific configurations.

3. The I18NProvider is loaded to get the CMS content in the locale speficied by the user configuration.
This is needed to provide internationalization support to the application.
*/

const ProtectedRoutes: React.FC = () => {

    let logged = useSelector<StoreState, boolean>(state => state.server.logged_in);

    let [checkingLogin, setCheckingLogin] = useState<boolean>(true);

    // Initially check if logged in the server
    useEffect(
        () => {
            SessionManager.checkLoggedIn(setCheckingLogin);
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (logged) {
        return (
            <>
                <IdleTimer
                    timeout={3600000} // 1 hour
                    onIdle={SessionManager.logout}
                />
                <div className={'top-wrapper'} >
                    <FeatureToggleProvider>
                        <UserConfig>
                            <I18NProvider>
                                <ImpersonificationModeBar />
                                <div className={'content-wrapper'}>
                                    <Header />
                                    
                                    <Admin />
                                    <Dashboard />
                                    
                                    <Footer />
                                </div>
                            </I18NProvider>
                        </UserConfig>
                    </FeatureToggleProvider>
                </div>
            </>
        );
    }

    if (checkingLogin) {
        return <LoaderSpinner />;
    }

    return null;
};

export default ProtectedRoutes;