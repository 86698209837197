import React, { useEffect } from 'react';
import {useState} from "react";

import { ImpersonificationData } from './types';

import Apirest from '../../Content/Apirest';
import SessionManager from '../../Authentication/SessionManager';
import { Modal, Badge, Button } from "react-bootstrap";
import { useContent } from '../../Content/cms';
import DataFrame from '../../Commons/Components/DataFrame';
import {Row as DataFrameRow} from '../../Commons/Components/DataFrame/types';

// Import the interfaces
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faCircle } from "@fortawesome/free-solid-svg-icons";


interface ImpersonificationProps {
    participant_id?: string
    show?: boolean
    disable_close_action?: boolean
    onHide?: () => void
}

const Impersonification: React.FC<ImpersonificationProps> = (props) => {

    let getContent = useContent();
    
    let [impersonifications, setImpersonifications] = useState<ImpersonificationData[]>([]);
    let [impersonificationDataFrame, setImpersonificationDataFrame] = useState<DataFrameRow[]>([]);

    useEffect(
        () => {
            Apirest.get_available_impersonifications(setImpersonifications);
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(
        () => {
            setImpersonificationDataFrame(impersonification2Dataframe(impersonifications))
        }, [impersonifications] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const onSelectImpersonification = (e: any) => {
        let index = parseInt(e.target.value); 
        let impersonification = impersonifications[index];
        
        SessionManager.impersonificate(
            impersonification.examiner.id,
            impersonification.mode
        );
    };
    
    const isImpersonify = JSON.parse(localStorage.getItem('impersonification') || "null");

    function impersonification2row(impersonifications: ImpersonificationData, idx:number) {
        let imp_index = idx
        return [
            <FontAwesomeIcon icon={faUserFriends} color={'#5CB6E7'}/>,
            <button className={'text-btn link-button '} value={imp_index} onClick={onSelectImpersonification}>{impersonifications.examiner.first_name + ' ' + impersonifications.examiner.last_name}</button>,
            <Badge variant="warning">{impersonifications.mode}</Badge>,
            isImpersonify !== null && (isImpersonify.mode === impersonifications.mode && isImpersonify.examiner_id === impersonifications.examiner.id) ? <FontAwesomeIcon icon={faCircle} size='sm' color={'#5CB6E7'}/> : ' ',
        ]
    }


    const impersonification2Dataframe = (impersonifications: ImpersonificationData[]) : DataFrameRow[] => {
        return impersonifications.map((imp, idx) => impersonification2row(imp, idx));
    }

    const deImpersonifyButton = <Button className={'btn-primary'} disabled={isImpersonify === null} onClick={() => SessionManager.deimpersonificate()}>{getContent('impersonification__deimpersonify_button')}</Button>
    
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop={props.disable_close_action ? 'static' : true}
            keyboard={!props.disable_close_action}
            size={'lg'}
            centered
        >
            <Modal.Header className={'mb-0 pb-0 mr-2'} closeButton={!props.disable_close_action}>
                <Modal.Title className={' pl-2 pt-0 modal-header-subtitle'}>{getContent('impersonification__modal_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'no-border'}>
                 <DataFrame
                    dataframe={impersonificationDataFrame}
                    pageSize={5}
                    striped={true}
                    columnClassName={'text-right'}
                    className={'text-left'}
                />      
                {deImpersonifyButton}          
            </Modal.Body>
        </Modal>
    );
}

export default Impersonification;
