import {ParticipantsReducer} from "./participants/reducer";
import {ExaminerReducer} from "./examiner/reducer";
import {CalendarReducer} from "./calendar/reducer";
import {DirtyStateReducer} from './dirtyState/reducer';
import {ServerReducer} from "./server/reducer";
import {PopUpAlertReducer} from "./popUpAlert/reducer";
import {i18Reducer} from "./i18n/reducer";
import {SurveyReducer} from "./surveys/reducer";
import {ProtocolReducer} from "./protocol/reducer";
import {FeatureToggleReducer} from "./featureToggle/reducer";
import {UserReducer} from "./user/reducer";

// Import middleware
import trackingMiddleware from './trackingMiddleware';

import {createStore, combineReducers, applyMiddleware} from 'redux';
import {logger} from 'redux-logger';

const storeReducer = combineReducers({
    i18n: i18Reducer,
    participants: ParticipantsReducer,
    user: UserReducer,
    examiner: ExaminerReducer,
    calendar: CalendarReducer,
    dirtyState: DirtyStateReducer,
    server: ServerReducer,
    popUpAlert: PopUpAlertReducer,
    survey: SurveyReducer,
    protocol: ProtocolReducer,
    featureToggle: FeatureToggleReducer
});

export type StoreState = ReturnType<typeof storeReducer>

export const store = createStore(
    storeReducer,
    applyMiddleware(logger, trackingMiddleware)
);
