import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Apirest from '../../Content/Apirest';
import SiteMap from "../../Commons/SiteMap";
import { IconButton } from '../../Commons/MultipleComponents';
import { useContent } from '../../Content/cms';

// Import types
import { ActivityHistory } from './ActivityHistory/types';
import { User, Locale } from '../../Dashboard/interfaces';
import { Organization, ExaminerStats } from './types';

// Import the store
import { StoreState } from "../../store";
import { UserStoreState } from '../../store/user/types';

// Import JSX Components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { faCog, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { RowSpinner } from "../../Commons/MultipleComponents";
import UserHeader from "../../Commons/Components/Examiner/Header";
import DataFrame from '../../Commons/Components/DataFrame';
import UserForm from './UserForm';
import AdminActivityHistory from './ActivityHistory';

import './index.css';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Manager: React.FC = () => {

    let history = useHistory();

    let getContent = useContent();

    let user = useSelector<StoreState, UserStoreState>((state: StoreState) => state.user);

    let [events, setEvents] = useState<ActivityHistory>({protocolres: [], surveyres: [], participants: []});
    let [stats, setStats] = useState<ExaminerStats[]>([]);
    let [users, setUsers] = useState<User[]>([]);
    let [focusedUser, setFocusedUser] = useState<User | undefined>();
    let [fetchingData, setFetchingData] = useState<boolean[]>([true,true,true,true]);
    let [organization, setOrganization] = useState<Organization | null>(null);
    let [locales, setLocales] = useState<Locale[]>([]);
    let [createNewExaminer, setCreateNewExaminer] = useState(false);
    let [impersonificationModes, setImpersonificationModes] = useState<string[]>([]);
    let [showActivityHistory, setShowActivityHistory] = useState(false);

    let [availableTags, setAvailableTags] = useState<string[]>([]);

    useEffect(
        loadOrganizationData, [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function loadOrganizationData() {
        setFetchingData(p => [true,true,true,true]);

        Apirest.get_organization_users((response: User[]) => {
            setUsers(response);
            setFetchingData(p => [false, p[1], p[2], p[3]]);
        });
        Apirest.get_organization((response: Organization) => {
            setOrganization(response);
            setFetchingData(p => [p[0], false, p[2], p[3]]);
        });
        Apirest.get_organization_events((response: ActivityHistory) => {
            setEvents(response);
            setFetchingData(p => [p[0], p[1], false, p[3]]);
        });
        Apirest.get_organization_stats((response: ExaminerStats[]) => {
            setStats(response);
            setFetchingData(p => [p[0], p[1], p[2], false]);
        });

        Apirest.get_impersonification_modes(setImpersonificationModes);

        // Locale doesn't need to be fetched every time and be used right away. We don't wait for it to be fetched
        Apirest.get_locales(setLocales);
    }

    useEffect(
        () => {
            setAvailableTags( users.flatMap(user => user.tags).filter((item, i, ar) => ar.indexOf(item) === i));
        },[users]
    );
   
    function onHideModal() {
        setCreateNewExaminer(false);
        setFocusedUser(undefined);
    }

    function user2row(user: User) {

        let user_stats = {
            number_of_participants: '-',
            number_of_protocolsres_completed: '-',
            number_of_surveyres: '-'
        };

        if (user.roles.examiner_id !== null) {
            let stat = stats.find(s => s.examiner_id === user.roles.examiner_id);
            if (stat === undefined) {
                user_stats = {
                    number_of_participants: 'error not found',
                    number_of_protocolsres_completed: 'error not found',
                    number_of_surveyres: 'error not found'
                };
            } else {
                user_stats = {
                    number_of_participants: stat.number_of_participants.toString(),
                    number_of_protocolsres_completed: stat.number_of_protocolsres_completed.toString(),
                    number_of_surveyres: stat.number_of_surveyres.toString()
                };
            }
        }

        return [
            <>
            <p className={'p-0 mb-0'}>{user.first_name + ' ' + user.last_name}</p>
            {user.tags.length >= 0 ?  
                user.tags.map(
                    (t, i) => <Badge key={i.toString()}  className={'mr-1 ml-0 badge-tags'}>{t}</Badge>
                ) : null
            } 
        </>,
            [
                user.roles.manager_id !== null ? getContent('admin__manager') : undefined,
                user.roles.examiner_id !== null ? getContent('admin__examiner') : undefined
            ].filter(e => e).join(', '),
            <p className={'text-center'}>{user_stats.number_of_participants}</p>,
            <p className={'text-center'}>{user_stats.number_of_surveyres}</p>,
            <p className={'text-center'}>{user_stats.number_of_protocolsres_completed}</p>,
            <IconButton
                onClick={() => setFocusedUser(user)}
                icon={{ icon: faCog, color: "#5CB6E7" }}
                text={getContent('admin__edit_user')}
            />
        ]
    }

    if (user.info && !user.info.roles.is_manager) {
        // Here I use a replace instead of push to avoid the user to comback to the home page from the browser buttons
        history.replace(SiteMap.getFullURL('dashboard'));
        return null;
    }

    let rows = users.map(user2row);
    let total_surveyres = stats.map(s => s.number_of_surveyres).reduce((a, b) => a + b, 0);
    let total_protocolres_completed = stats.map(s => s.number_of_protocolsres_completed).reduce((a, b) => a + b, 0);

    if (fetchingData.some(e => e)) {
        return <Container className={'mt-3'}>
            <RowSpinner show={true} />
        </Container>
    }

    let usersDataframeHeader = [
        <p>{getContent('admin__users_list_header__users')}</p>,
        <p>{getContent('admin__users_list_header__role')}</p>,
        <p>{getContent('admin__users_list_header__participants')}</p>,
        <p>{getContent('admin__users_list_header__survey_taken')}</p>,
        <p>{getContent('admin__users_list_header__protocol_taken')}</p>,
        ''
    ];

    return (
        <Container fluid className={'content-wrapper'}>
            <Container className={'patients fluid d-flex-column bg-white'} fluid>
                <UserHeader />
            </Container>

            <Container className={'bg-light'} fluid>
                <Row className={'p-3'}>
                    <Col>
                        <h4 className={'text-left text-primary mt-3'}>
                            {organization!.name}
                        </h4>
                    </Col>
                </Row>
                <Row className='px-3 mb-3 d-flex justify-content-between'>
                    <Col md={8}>
                    <ListGroup horizontal={'lg'}>
                        <ListGroupItem className={'d-flex'}>
                            <p className='px-3 mb-0'> {getContent('admin__surveys_taken')}</p>
                            <p className={'text-info mx-3 mb-0'}><b>{total_surveyres}</b></p>
                        </ListGroupItem>
                        <ListGroupItem className={'d-flex'}>
                            <p className='px-3 mb-0'>{getContent('admin__protocol_finished')}</p>
                            <p className={'text-info fw-bold mx-3 mb-0'}> <b>{total_protocolres_completed}</b></p>
                        </ListGroupItem>
                    </ListGroup>
                    </Col>
                    <Col xs={4} className='text-right'>
                        <Button onClick={() => setShowActivityHistory(true)}>
                            {getContent('admin__view_activity_history')}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Container className={'bg-white'} fluid>
                        <Row>
                            <Col>
                                <h4 className={'text-left text-primary mt-3 p-3'}>
                                    {getContent('admin__organization_users__title')}
                                </h4>
                            </Col>
                        </Row>
                        <Row className='d-flex flex-row-reverse mx-3'>
                            <Col  className='text-right px-3'>
                                <Button onClick={() => setCreateNewExaminer(true)}>
                                    <FontAwesomeIcon icon={ faPlusCircle} className={'mr-2'}/> {getContent('admin__create_user')}
                                </Button>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'mx-3'}>
                                <DataFrame
                                    header={usersDataframeHeader}
                                    dataframe={rows}
                                    pageSize={10}
                                    className={'header-left header-bordered'}
                                    showAllOption={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <UserForm
                show={createNewExaminer || focusedUser !== undefined}
                onHide={onHideModal}
                onSaveChanges={loadOrganizationData}
                availableProtocols={organization!.available_protocols}
                availableSurveys={organization!.available_surveys}
                impersonificationModes={impersonificationModes}
                organizationOwnerID={organization!.owner}
                availableTags={availableTags}
                users={users}
                locales={locales}
                user={focusedUser}
            />

            <AdminActivityHistory
                show={showActivityHistory}
                onHide={() => setShowActivityHistory(false)}
                events={events}
            />
        </Container>
    );
};

export default Manager;
