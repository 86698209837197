import $ from 'jquery';
import ServerConnection, {ResponseCallback, FailCallback} from '../ServerConnection';
import { downloadFromURL } from '../../Commons/Utils/downloadFile';

import config from "../../Config";
import urls from "./urls.json";

// Import interfaces
import { Country, Examiner, Locale } from "../../Dashboard/interfaces";
import { FormSubmitData } from '../../Commons/Components/FormFields/types';
import { ServerError } from "../../store/server/types";
import { ProtocolData } from '../../Dashboard/Protocol/types';

const Apirest = {

    conextion: new ServerConnection(config.apirest.baseURL),

    /** Server reports */

    server_report: (path: string, data: any, success: ResponseCallback = () => {} , fail: FailCallback = () => {}) => {
        $.post({
            url: config.apirest.baseURL + path,
            data: JSON.stringify(data),
            contentType: 'application/json',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')},
            xhrFields: {withCredentials: true}
        })
            .then(Apirest.conextion.preprocessAndSuccess(path, 'POST', success))
            .fail(fail)
            .then(() => {console.log('Finished post server error report:', path)});
    },

    send_server_error_user_report: (serverError: ServerError, userComment: string, success: ResponseCallback, fail: ResponseCallback = () => {}) => Apirest.server_report(
        urls.reports.server_error_user_report,
        {
            error: serverError.error,
            url: serverError.url,
            method: serverError.method,
            data: JSON.stringify(serverError.data),
            user_comment: userComment
        },
        success,
        fail
    ),

    /*** Authentication */

    login: (email: string , password: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.login,
        {email: email, password: password},
        success,
        fail,
        fail,
    ),

    check_logged_in: (success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.verifyJTWToken,
        {token: localStorage.getItem('jwt_token')},
        success,
        fail,
        fail
    ),

    refresh_token: (success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.refreshToken,
        {refresh: localStorage.getItem('jwt_refresh')},
        success,
        fail
    ),

    send_reset_password_email(email: string, success: ResponseCallback) {
        Apirest.conextion.post(
            urls.authentication.password_reset.request,
            {email: email},
            success,
            success
        );
    },

    validate_reset_password_token: (token: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.password_reset.validate_token,
        {token: token},
        success,
        fail
    ),

    set_new_password: (token: string, password: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.password_reset.confirm,
        {token: token, password: password},
        success,
        fail
    ),

    /** Participant entries */

    update_participant: (participant_id: string, data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.patch(
        urls.user.participants.participants.replace('{participant_id}', participant_id),
        data,
        success,
        fail
    ),

    get_participants: (success: ResponseCallback) => Apirest.conextion.get(
        urls.user.participants.participants.replace('{participant_id}/', ''),
        {},
        success
    ),

    create_participant: (data: any, success: ResponseCallback, fail: ResponseCallback = () => {}) => Apirest.conextion.post(
        urls.user.participants.participants.replace('{participant_id}/', ''),
        data,
        success,
        fail
    ),

    archive_participant: (participant_id: string) => Apirest.conextion.patch(
        urls.user.participants.archive.replace('{participant_id}', participant_id),
        {},
        () => {}
    ),

    restore_participant: (participant_id: string) => Apirest.conextion.patch(
        urls.user.participants.restore.replace('{participant_id}', participant_id),
        {},
        () => {}
    ),

    /** organization entries */

    get_organization: (success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.organization,
        {},
        success
    ),

    get_organization_users: (success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.user.replace('{user_id}/', ''),
        {},
        success
    ),

    create_user: (data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.organization.user.replace('{user_id}/', ''),
        data,
        success,
        fail
    ),

    update_user: (user_id: number, data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.patch(
        urls.organization.user.replace('{user_id}', user_id.toString()),
        data,
        success,
        fail
    ),

    get_organization_examiners: (success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.examiners.replace('{examiner_id}/', ''),
        {},
        success
    ),

    get_examiner: (examiner_id: string, success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.examiners.replace('{examiner_id}', examiner_id),
        {},
        success
    ),

    create_examiner: (data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.organization.examiners.replace('{examiner_id}/', ''),
        data,
        success,
        fail
    ),

    update_examiner: (examiner_id: string, data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.patch(
        urls.organization.examiners.replace('{examiner_id}', examiner_id),
        data,
        success,
        fail
    ),

    delete_examiner: (examiner_id: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.delete(
        urls.organization.examiners.replace('{examiner_id}', examiner_id),
        success,
        fail
    ),

    create_manager: (data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.organization.managers.replace('{manager_id}/', ''),
        data,
        success,
        fail
    ),

    delete_manager: (manager_id: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.delete(
        urls.organization.managers.replace('{manager_id}', manager_id),
        success,
        fail
    ),

    get_impersonification_modes: (success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.impersonification.modes,
        {},
        success
    ),

    get_impersonifications: (user_id: number, success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.impersonification.impersonifications.replace('{user_id}', user_id.toString()),
        {},
        success
    ),

    update_impersonifiations: (user_id: number, data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.patch(
        urls.organization.impersonification.impersonifications.replace('{user_id}', user_id.toString()),
        data,
        success,
        fail
    ),

    get_organization_events: (success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.events,
        {},
        success
    ),

    get_organization_stats: (success: ResponseCallback) => Apirest.conextion.get(
        urls.organization.stats,
        {},
        success
    ),

    /** Users entries */

    get_user_info: (success: ResponseCallback) => Apirest.conextion.get(
        urls.user.user_info,
        {},
        success
    ),
    
    get_examiner_info: (success: (response: Examiner) => void) => Apirest.conextion.get(
        urls.user.examiner_info,
        {},
        success
    ),

    /*** Files */

    get_files: (participant_id: string, success: ResponseCallback = () => {}, fail: ResponseCallback = () => {}) => Apirest.conextion.get(
        urls.files.files.replace('{participant_id}', participant_id).replace('{file_id}/',''),
        {},
        success,
        fail
    ),

    upload_files: (participant_id: string, files: FileList, success: ResponseCallback = () => {}, fail: FailCallback = () => {}, callback: () => void = () => {}) => {
        
        let formDatas = Array.from(files).map(file => {
            let formData = new FormData();
            formData.append('file', file);
            return formData;
        });

        Apirest.conextion.post_files(
            urls.files.files.replace('{participant_id}', participant_id).replace('{file_id}/',''),
            formDatas,
            success, // Individual file upload success
            fail, // Individual file upload error
            callback // Final general callback after requests are finished
        );
    },

    delete_file: (participant_id: string, file_id: string, success: ResponseCallback = () => {}, fail: FailCallback = () => {}) => Apirest.conextion.delete(
        urls.files.files.replace('{participant_id}', participant_id).replace('{file_id}', file_id),
        success,
        fail
    ),

    download_file: (participant_id: string, file_id: string, fail: FailCallback = () => {}) => Apirest.conextion.get(
        urls.files.presigned_url.replace('{participant_id}', participant_id).replace('{file_id}', file_id),
        {},
        downloadFromURL,
        fail
    ),

    /*** Protocols */
    get_protocol_res_audio_url_presigned: (participant_id: string, protocolResID: string, protocol_item_id: string, success: ResponseCallback) => Apirest.conextion.get(
        urls.protocols.responses.item.audio_presigned_url.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolResID).replace('{protocol_item_id}', protocol_item_id),
        {},
        success
    ),

    get_protocol_res_audio_src: (participant_id: string, protocolResID: string, protocol_item_id: string, success: ResponseCallback) => Apirest.conextion.get(
        urls.protocols.responses.item.audio_src.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolResID).replace('{protocol_item_id}', protocol_item_id),
        {},
        success
    ),

    get_protocol_res_audio_presigned_url: (participant_id: string, protocolResID: string, protocol_item_id: string, success: ResponseCallback) => Apirest.conextion.get(
        urls.protocols.responses.item.audio_presigned_url.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolResID).replace('{protocol_item_id}', protocol_item_id),
        {},
        success
    ),

    download_protocol_res_audio: (participant_id: string, protocolResID: string, protocol_item_id: string) => Apirest.get_protocol_res_audio_presigned_url(
        participant_id,
        protocolResID,
        protocol_item_id,
        downloadFromURL
    ),

    get_examiner_protocols: (success: ResponseCallback<ProtocolData[]>) => Apirest.conextion.get(
        urls.protocols.examiner,
        {},
        success
    ),

    create_protocol_res: (participant_id: string, protocol_id: string, success: ResponseCallback<{id: number}>) => Apirest.conextion.post(
        urls.protocols.responses.responses.replace('{participant_id}', participant_id).replace('{protocol_res_id}/', ''),
        {'protocol_id': protocol_id},
        success
    ),

    send_protocol_item_response: (participant_id: string, protocolResID: string, protocol_item_id: string, speech: Blob, success: ResponseCallback, fail: FailCallback = () => {}) => {

        let formData = new FormData();

        formData.append('speech', speech);

        Apirest.conextion.post_files(
            urls.protocols.responses.item.item.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolResID).replace('{protocol_item_id}', protocol_item_id),
            [formData],
            success,
            fail
        );
    },

    send_protocol_item_skip: (participant_id: string, protocolResID: string, protocol_item_id: string, purpose: string, success: ResponseCallback, fail: FailCallback = () => {}) => {

        Apirest.conextion.post(
            urls.protocols.responses.item.item.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolResID).replace('{protocol_item_id}', protocol_item_id),
            {skipped_purpose: purpose},
            success,
            fail
        );
    },

    get_protocolres: (participant_id: string, protocolres_id: string, success: ResponseCallback) => Apirest.conextion.get(
        urls.protocols.responses.responses.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolres_id),
        {},
        success
    ),

    get_participant_all_protocolres: (participant_id: string, success: ResponseCallback) => Apirest.conextion.get(
        urls.protocols.responses.responses.replace('{participant_id}', participant_id).replace('{protocol_res_id}/', ''),
        {},
        success
    ),

    get_participant_protocolres_evolution_data: (participant_id: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.get(
        urls.protocols.responses.evolution.replace('{participant_id}', participant_id),
        {},
        success,
        fail
    ),

    archive_protocol_res: (participant_id: string, protocolres_id: string) => Apirest.conextion.patch(
        urls.protocols.responses.archive.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolres_id),
        {},
        () => {}
    ),

    restore_protocol_res: (participant_id: string, protocolres_id: string) => Apirest.conextion.patch(
        urls.protocols.responses.restore.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolres_id),
        {},
        () => {}
    ),

    add_protocol_res_comment: (participant_id: string, protocolres_id: string, comment: string, success: ResponseCallback) => Apirest.conextion.patch(
        urls.protocols.responses.comment.replace('{participant_id}', participant_id).replace('{protocol_res_id}', protocolres_id),
        {comment: comment},
        success
    ),

    /** Surveys */

    get_examiner_surveys: (success: ResponseCallback) => Apirest.conextion.get(
        urls.surveys.examiner,
        {},
        success
    ),

    send_survey_response: (participant_id: string, survey_id: string, response: FormSubmitData, success: ResponseCallback, fail: FailCallback = () => {}) => Apirest.conextion.post(
        urls.surveys.responses.responses.replace('{participant_id}', participant_id).replace('{survey_response_id}/', ''),
        {survey_id: survey_id, response: response},
        success,
        fail
    ),

    get_participant_survey_responses: (participant_id: string, success: ResponseCallback, fail: FailCallback = () => {}) => Apirest.conextion.get(
        urls.surveys.responses.responses.replace('{participant_id}', participant_id).replace('{survey_response_id}/', ''),
        {},
        success,
        fail
    ),

    get_survey_response: (participant_id: string, survey_response_id: string, success: ResponseCallback, fail: FailCallback = () => {}) => Apirest.conextion.get(
        urls.surveys.responses.responses.replace('{participant_id}', participant_id).replace('{survey_response_id}', survey_response_id),
        {},
        success,
        fail
    ),

    archive_survey_response: (participant_id: string, survey_response_id: string) => Apirest.conextion.patch(
        urls.surveys.responses.archive.replace('{participant_id}', participant_id).replace('{survey_response_id}', survey_response_id),
        {},
        () => {}
    ),

    restore_survey_response: (participant_id: string, survey_response_id: string) => Apirest.conextion.patch(
        urls.surveys.responses.restore.replace('{participant_id}', participant_id).replace('{survey_response_id}', survey_response_id),
        {},
        () => {}
    ),

    /** Locales */

    get_locales: (success: ResponseCallback<Locale[]>) => Apirest.conextion.get(
        urls.cms.locales,
        {},
        success
    ),

    get_countries: (success: ResponseCallback<Country[]>) => Apirest.conextion.get(
        urls.cms.countries,
        {},
        success
    ),

    /** Calendar */

    get_calendar_events: (success: ResponseCallback) => Apirest.conextion.get(
        urls.calendar_events,
        {},
        success
    ),

    /** Takeout */

    get_all_takeouts: (success: ResponseCallback) => Apirest.conextion.get(
        urls.takeouts.takeouts.replace('{takeout_uuid}', ''),
        {},
        success
    ),

    get_all_org_takeouts: (success: ResponseCallback) => Apirest.conextion.get(
        urls.takeouts.org_takeouts.replace('{takeout_uuid}', ''),
        {},
        success
    ),

    create_takeout: (success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.takeouts.takeouts.replace('{takeout_uuid}', ''),
        {},
        success,
        fail
    ),

    create_org_takeout: (success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.takeouts.org_takeouts.replace('{takeout_uuid}', ''),
        {},
        success,
        fail
    ),

    download_takeout: (uuid: string) => Apirest.conextion.post(
        urls.takeouts.get_download_link.replace('{takeout_uuid}', uuid),
        {},
        downloadFromURL
    ),

    /** Impersonification */

    impersonificate: (examiner_id: number, mode: string, sucess: ResponseCallback) => Apirest.conextion.post(
        urls.user.impersonification.impersonificate.replace('{examiner_id}', examiner_id.toString()).replace('{mode}', mode),
        {},
        sucess
    ),

    deimpersonificate: (sucess: ResponseCallback) => Apirest.conextion.post(
        urls.user.impersonification.deimpersonificate,
        {},
        sucess
    ),

    get_available_impersonifications: (success: ResponseCallback) => Apirest.conextion.get(
        urls.user.impersonification.impersonifications,
        {},
        success
    ),

    get_toggle_table: (success: ResponseCallback) => Apirest.conextion.get(
        urls.user.impersonification.toggle_table,
        {},
        success
    ),
};

export default Apirest;
